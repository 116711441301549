import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CtaBlue from '../CtaBlue';
import InnerImage from '../InnerImage/innerImage';

const styles = theme =>
  createStyles({
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

});

const ProjectCard = ({
  imgSrc,
  ctaTitle = '',
  ctaHref = '/',
  ctaText = '',
  classes
}) => {
  return (
    <Grid
      container
      direction="column"
    >
      <InnerImage
        image={{ className: classes.img, src: imgSrc }}
      />
      <CtaBlue
        ctaTitle={ctaTitle}
        ctaHref={ctaHref}
        ctaText={ctaText}
      />
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(ProjectCard);
