import React from 'react';
import { withStyles } from '@material-ui/core/styles';

//Images
import { OurProjectsImages } from '../imageURLs';

import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CtaBlock from '../components/CtaBlock';
import CTAButton from '../components/CTAButton/CTAButton';
import ProjectCard from '../components/ProjectCard/ProjectCard';

const ourProjectsData = [
  {
    image: OurProjectsImages.image6,
    ctaTitle: 'Bribie Soccer Complex, Moreton Bay Regional Council',
    ctaHref: '/bribie-soccer-complex',
  },
  {
    image: OurProjectsImages.image7,
    ctaTitle: 'D.M. Henderson Park, Brisbane City Council',
    ctaHref: '/henderson-park',
  },
  {
    image: OurProjectsImages.image8,
    ctaTitle: 'Stanley Day Park, Brisbane City Council',
    ctaHref: '/stanley-day-park',
  },
  {
    image: OurProjectsImages.image9,
    ctaTitle: 'Giffin Park, Coorparoo, Brisbane City Council',
    ctaHref: '/giffin-park',
  },
  {
    image: OurProjectsImages.image5,
    ctaTitle: 'South Pine Sports Complex, Moreton Bay Regional Council',
    ctaHref: '/south-pine-sports-complex',
  },
  {
    image: OurProjectsImages.image1,
    ctaTitle: 'Dolphins Stadium, Redcliffe',
    ctaHref: '/dolphins-stadium',
  },
  {
    image: OurProjectsImages.image2,
    ctaTitle: "O'Callaghan Park, Zillmere",
    ctaHref: '/o-callaghan-park',
  },
  {
    image: OurProjectsImages.image4,
    ctaTitle: 'Mitchelton Park, Gaythorne',
    ctaHref: '/mitchelton-park',
  },
  {
    image: OurProjectsImages.image3,
    ctaTitle: 'Taigum Place Park, Taigum',
    ctaHref: '/taigum-park',
  },
]


const OurProjectsPage = ({children}) => (

<React.Fragment>

  <SEO
    title="Our Projects - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
    ]}
  />

  {ourProjectsData.map(({image, ctaTitle, ctaHref}, i) => (
    <ProjectCard key={i} imgSrc={image} ctaTitle={ctaTitle} ctaHref={ctaHref} ctaText="Learn More" />
  ))}

  <CtaBlock title="Discover the TVT difference">
    <CTAButton
      inverted
      ctaHref="/contact"
      ctaText="Get a quote"
    />
  </CtaBlock>

  {children}
</React.Fragment>


)

export default withQuoteWizard(withStyles({ withTheme: true })(OurProjectsPage));