import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CTAButton from './CTAButton/CTAButton';

const styles = theme =>
  createStyles({
    ctaWrap: {
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'center',
      alignItems: "center",
      padding: "15px 10px",
      flexDirection: "column",
      [theme.breakpoints.up(380)]: {
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.up(960)]: {
        textAlign: "center",
        justifyContent: "center",
        padding: "30px 15px"
      },

    },
    ctaTitle: {
      fontSize: 20,
      fontWeight: '300',
      paddingBottom: '10px',
      textAlign: 'center',
      [theme.breakpoints.up(380)]: {
        maxWidth: '260px',
        paddingBottom: 0,
        paddingRight: '25px',
        textAlign: 'left',
      },
      // [theme.breakpoints.up(450)]: {
      //   fontSize: 24,
      // },
      [theme.breakpoints.up(767)]: {
        maxWidth: '100%',
        textAlign: 'center',
      },
      [theme.breakpoints.up(960)]: {
        fontSize: 32,
        letterSpacing: '-0.3px',
        paddingBottom: '4px'
      },
    }
  });

const CtaBlue = ({
  classes,
  ctaTitle,
  ctaHref,
  ctaText,
}) => (

  <Grid
    container
    className ={classes.ctaWrap}
  >

    <Typography
      variant="h2"
      color="secondary"
      className={classes.ctaTitle}
    > 
    {ctaTitle} 
    </Typography>

    <CTAButton
        ctaText={ctaText}
        ctaHref={ctaHref}
        className={classes.ctaButton}
        tertiary
    >
    </CTAButton>

  </Grid>
    
  );


export default withStyles(styles, { withTheme: true })(CtaBlue);
